import React, { useEffect, useState } from "react";

export default function Music() {
  const [playlist, setPlaylist] = useState("");
  const [album, setAlbum] = useState("");

  useEffect(() => {
    // Fetch the Spotify oEmbed data for the playlist
    fetch(
      "https://open.spotify.com/oembed?url=https://open.spotify.com/playlist/2lkp2Rem3PzkA1pSM20GNi"
    )
      .then((response) => response.json())
      .then((data) => setPlaylist(data.html));

    // Fetch the Spotify oEmbed data for the album
    fetch(
      "https://open.spotify.com/oembed?url=https://open.spotify.com/album/5Hbc0Y7u8vpjF6ieTHaqqk"
    )
      .then((response) => response.json())
      .then((data) => setAlbum(data.html));
  }, []);

  return (
    <div
      name="music"
      className="w-full md:h-screen bg-[#282828] text-[#dbe8f4] font-[montserrat]"
    >
      <div className="max-w-[1000px]  mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8 mt-20">
          <p className="text-4xl text-[#6ea3ae] font-bold inline">Music</p>
        </div>
        <div className="md:grid md:grid-cols-2 gap-6">
          <div className="mb-3">
            <p className="text-lg text-[#6ea3ae] font-bold">
              My Band's First EP
            </p>
            <p>
              This album features my drumming on all five songs. It was recorded
              at Meadowlark Studios in Williston, Vermont by Dan Kruglak.
            </p>
            <div dangerouslySetInnerHTML={{ __html: album }} />
          </div>
          <div className="mb-3">
            <p className="text-lg text-[#6ea3ae] font-bold">
              Vermont Artists Playlist
            </p>
            <p>
              This playlist includes music from various Vermont artists with
              whom I've had the pleasure of playing drums with for several live
              shows.
            </p>
            <div dangerouslySetInnerHTML={{ __html: playlist }} />
          </div>
        </div>
      </div>
    </div>
  );
}
