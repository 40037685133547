import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";

export default function Home() {
  return (
    <div name="home" className="w-full h-screen bg-[#282828]">
      {/* container */}
      <div className="max-w-[1100px] mx-auto px-8 flex flex-col justify-center h-full">
        <h1 className="  text-3xl sm:text-6xl font-bold font-[montserrat] text-[#6ea3ae]">
          Will Mashrick
        </h1>
        <p className=" text-4xl sm:text-6xl font-bold  text-[#476967]">
          DEVELOPER, DESIGNER, DRUMMER.
        </p>

        <div></div>
      </div>
    </div>
  );
}
