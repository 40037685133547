import React from "react";
import CCDemo from "../assets/CCDemo.jpg";
import RWChat from "../assets/RWChat.jpg";
import Jeopardy from "../assets/Jeopardy.jpg";

export default function Projects() {
  return (
    <div
      name="projects"
      className="w-full md:h-screen bg-[#282828] text-[#dbe8f4] font-[montserrat]"
    >
      <div className=" max-w-[1100px]  mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className=" pb-8 mt-20">
          <p className=" text-4xl text-[#6ea3ae] font-bold inline">Projects</p>
          <p className="py-2">Here are a few of my recent projects.</p>
        </div>

        {/* container for projects */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
          {/* Project 1 */}
          <div className="project-container">
            <div
              style={{ backgroundImage: `url(${CCDemo})` }}
              className="shadow-md shadow-[#040c16] group container rounded-md 
                flex justify-center text-center items-center content-div pointer-events-auto "
            >
              {/* Hover effect for images */}
              <div className="info-div ">
                <span className="  text-2xl font bold text-[#dbe8f4] tracking-wider ">
                  Career Clash
                </span>
                <div className="pt-8 text-center   ">
                  <a
                    href="https://github.com/WillMashrick/Career-Clash"
                    target="_blank"
                  >
                    <button
                      className="text-center rounded-lg px-3 py-2 m-1
                         bg-white text-gray-700 font-bold text-lg "
                    >
                      View Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="text-[#dbe8f4] text-sm mt-4">
              Web-based gamified job search application. Allows organizations to
              post job listings and individual job seekers to compare two job
              listings at a time and determine which job they prefer.
            </p>
          </div>

          {/* Project 2 */}
          <div className="project-container">
            <div
              style={{ backgroundImage: `url(${RWChat})` }}
              className="shadow-md shadow-[#040c16] group container rounded-md 
                flex justify-center text-center items-center mx-auto content-div "
            >
              {/* Hover effect for images */}
              <div className=" info-div   ">
                <span className="  text-2xl font bold text-[#dbe8f4] tracking-wider ">
                  RW Chat
                </span>
                <div className="  pt-8 text-center   ">
                  {/* eslint-disable-next-line */}
                  <a
                    href="https://github.com/WillMashrick/RW-Chat"
                    target="_blank"
                  >
                    <button
                      className="text-center rounded-lg px-3 py-2 m-1
                         bg-white text-gray-700 font-bold text-lg "
                    >
                      View Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="text-[#dbe8f4] text-sm mt-4 ">
              Web-based real-time chat application with user sign up and login
              and multiple chat rooms. Used Express, Postman and MongoDB to
              build and test server CRUD functionality. Built custom client side
              using React, Reactrap and CSS.
            </p>
          </div>

          {/* Project 3 */}
          <div className="project-container">
            <div
              style={{ backgroundImage: `url(${Jeopardy})` }}
              className="shadow-md shadow-[#040c16] group container rounded-md 
                flex justify-center text-center items-center mx-auto content-div  "
            >
              {/* Hover effect for images */}
              <div className=" info-div">
                <span className="  text-2xl font bold text-[#dbe8f4] tracking-wider ">
                  Jeopardy
                </span>
                <div className="pt-8 text-center   ">
                  {/* eslint-disable-next-line */}
                  <a
                    href="https://github.com/WillMashrick/Jeopardy-Board/tree/main"
                    target="_blank"
                  >
                    <button
                      className="text-center rounded-lg px-3 py-2 m-1
                         bg-white text-gray-700 font-bold text-lg "
                    >
                      View Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p className="text-[#dbe8f4] text-sm mt-4">
              Custom web-based Two-player Jeopardy game with two rounds and
              final Jeopardy. Created from scratch using HTML, CSS and vanilla
              JavaScript.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
