import React from "react";
import Will from "../assets/Will.jpg";
import Technology from "./Technology";

export default function About() {
  return (
    <div
      name="about"
      className="bg-[#476967] text-[#dbe8f4] font-[montserrat] min-h-screen"
    >
      <div className="container mx-auto flex flex-col md:flex-row justify-center items-center  py-16">
        <div className="md:w-1/2 mx-4 text-center mt-16">
          <img
            className="border-4 border-[#6eae79] mb-4 rounded-full w-64 h-64 ml-16"
            src={Will}
            alt="Will"
          />
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0 mx-4 text-center">
          <h2 className="text-[#6ea3ae] text-4xl font-bold mb-4">About Me</h2>
          <p className="text-2xl">My name is Will Mashrick.</p>
          <p className="mt-2">
            I'm a software developer, designer, and drummer based in Burlington,
            VT. I am driven by creativity and imagination. I specialize in
            blending technology and design to create captivating digital
            experiences. Welcome to my portfolio, where innovation meets
            artistry, transforming imagination into reality.
          </p>
        </div>
      </div>
      <Technology />
    </div>
  );
}
