import React from "react";

export default function Contact() {
  return (
    <div
      name="contact"
      className=" w-full h-screen bg-[#476967] text-[#dbe8f4] flex justify-center items-center p-4 font-[montserrat]"
    >
      <form
        method="POST"
        action="https://getform.io/f/861d707e-1ba7-4cc1-b27d-65613d73502b"
        className="flex flex-col max-w-[600px] w-full mt-20 "
      >
        <div>
          <p className="text-4xl text-[#6ea3ae] font-bold inline">Contact</p>
          <p className=" py-2"> Fill out the form below to get in touch!</p>
        </div>
        <input
          className=" bg-[#dbe8f4] p-2 text-gray-800 rounded-md"
          type="text"
          placeholder="Name"
          name="name"
        />
        <input
          className=" my-4 p-2 bg-[#dbe8f4] text-gray-800 rounded-md"
          type="text"
          placeholder="Email"
          name="email"
        />
        <textarea
          className=" bg-[#dbe8f4] p-2 text-gray-800 rounded-md"
          name="message"
          rows="10"
          placeholder="Message"
        ></textarea>
        <button className=" border-2 rounded-md hover:bg-[#6ea3ae] px-4 py-2 my-8 mx-auto flex items-center">
          Submit!
        </button>
      </form>
    </div>
  );
}
