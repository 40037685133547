import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import ImageModal from "./ImageModal";

import Dragonfly from "../assets/Dragonfly.jpg";
import CapeStars from "../assets/CapeStars.jpg";
import Chicken from "../assets/Chicken.jpg";
import Fly from "../assets/Fly.jpg";
import Guitar from "../assets/Guitar.jpg";
import James from "../assets/James.jpg";
import Quebec from "../assets/Quebec.jpg";
import Quebec2 from "../assets/Quebec2.jpg";
import MilkyWay from "../assets/MilkyWay.jpg";
import Philo from "../assets/Philo.jpg";
import Rain from "../assets/Rain.jpg";
import Rigby from "../assets/Rigby.jpg";
import ShelbStars from "../assets/ShelbStars.jpg";
import Tree from "../assets/Tree.jpg";
import WMLogo from "../assets/WMLogo5.png";
import CircleLogo from "../assets/Logo tutorial.png";
import Burger from "../assets/Burger-01.jpg";
import LakeWaves from "../assets/Lake Waves.png";
import CareerClashLogo from "../assets/Career-Clash2.jpg";
import GuitarDrawing from "../assets/Guitar-Drawing.jpg";
import HotDog from "../assets/Hot Dog.png";

export default function VisualArt() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { id: 1, Source: Dragonfly },
    { id: 2, Source: CapeStars },
    { id: 3, Source: Chicken },
    { id: 4, Source: Fly },
    { id: 5, Source: Guitar },
    { id: 6, Source: James },
    { id: 7, Source: Philo },
    { id: 8, Source: Rain },
    { id: 9, Source: MilkyWay },
    { id: 10, Source: Quebec },
    { id: 11, Source: Quebec2 },
    { id: 12, Source: Rigby },
    { id: 13, Source: ShelbStars },
    { id: 14, Source: Tree },
  ];

  const illustrations = [
    { id: 1, Source: WMLogo },
    { id: 2, Source: CircleLogo },
    { id: 3, Source: CareerClashLogo },
    { id: 4, Source: LakeWaves },

    { id: 5, Source: GuitarDrawing },
    { id: 6, Source: Burger },
    { id: 7, Source: HotDog },
  ];

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  return (
    <div
      name="visualart"
      className="w-full  bg-[#282828] text-[#dbe8f4] font-[montserrat] "
    >
      <div className=" max-w-[1000px] mx-auto  p-8 ">
        <div className=" pb-8 mt-20">
          <p className=" text-4xl text-[#6ea3ae] font-bold inline">
            Photography
          </p>
          <p className="py-2">A small sample of my work behind the lens.</p>
        </div>
        <div className=" columns-2 md:columns-3 gap-3    ">
          {images.map((image) => (
            <LazyLoad key={image.id} height={200}>
              <img
                key={image.id}
                className=" mb-3 rounded-md shadow-md shadow-[#040c16] "
                src={image.Source}
                alt={image.Source}
                onClick={() => openModal(image.Source)}
              />
            </LazyLoad>
          ))}
        </div>
        <div className=" py-8">
          <p className=" text-4xl text-[#6ea3ae] font-bold inline">
            Graphic Design & Illustration
          </p>
          <p className="py-2">
            Recently I have begun to explore graphic design. Below are a few
            examples.
          </p>
        </div>
        <div className=" columns-2 md:columns-3 gap-3    ">
          {illustrations.map((illustration) => (
            <LazyLoad key={illustration.id} height={200}>
              <img
                key={illustration.id}
                className=" mb-3 rounded-md shadow-md shadow-[#040c16] "
                src={illustration.Source}
                alt={illustration.Source}
                onClick={() => openModal(illustration.Source)}
              />
            </LazyLoad>
          ))}

          {/* Render the modal if it's open */}
          {modalOpen && (
            <ImageModal imageUrl={selectedImage} onClose={closeModal} />
          )}
        </div>
      </div>
    </div>
  );
}
