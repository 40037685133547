import React from "react";
import { FaTimes } from "react-icons/fa";

export default function ImageModal({ imageUrl, onClose }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/70 z-50 ">
      <div className="max-w-screen-lg bg-white p-4 rounded-lg shadow-lg">
        <img
          className=" max-w-[60vw] max-h-[80vh] object-contain"
          src={imageUrl}
          alt="Full-Screen"
        />
        <FaTimes
          onClick={onClose}
          className="absolute top-2 right-2 text-white text-2xl cursor-pointer"
        />
      </div>
    </div>
  );
}
