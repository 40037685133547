import { useState } from "react";
import Logo from "../assets/WMLogo4.png";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";
import { BsFillPersonLinesFill } from "react-icons/bs";

import resume from "../assets/WillMashrickResume.pdf";

export default function TopNav() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  return (
    <>
      <div className="fixed w-full h-[90px] flex justify-between items-center px-4 bg-[#282828] font-montserrat text-[#dbe8f4] border-[#dbe8f4] border-b-[.5px] ">
        <div>
          <a href="/">
            <img
              className=" animate-spin"
              src={Logo}
              alt="Logo"
              style={{ width: "70px" }}
            />
          </a>
        </div>
        <ul className="hidden md:flex">
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/"
            >
              Home
            </a>
          </li>
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/about"
            >
              About
            </a>
          </li>
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/projects"
            >
              Projects
            </a>
          </li>
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/visualart"
            >
              Visual Art
            </a>
          </li>
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/music"
            >
              Music
            </a>
          </li>
          <li>
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/contact"
            >
              Contact
            </a>
          </li>
        </ul>

        <div onClick={handleClick} className="md:hidden z-10">
          {!nav ? <FaBars /> : <FaTimes />}
        </div>

        <ul
          className={
            !nav
              ? "hidden".toString()
              : " absolute top-0 left-0 w-full h-screen bg-[#282828] flex flex-col justify-center items-center".toString()
          }
        >
          <li className="py-6 text-4xl ">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/"
            >
              Home
            </a>
          </li>
          <li className="py-6 text-4xl ">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/about"
            >
              About
            </a>
          </li>
          <li className="py-6 text-4xl ">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/projects"
            >
              Projects
            </a>
          </li>

          <li className="py-6 text-4xl ">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/visualart"
            >
              Visual Art
            </a>
          </li>
          <li className="no-underline py-6 text-4xl  text-[#dbe8f4] hover:text-[#6ea3ae]">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/music"
            >
              Music
            </a>
          </li>
          <li className="py-6 text-4xl ">
            <a
              className="no-underline text-[#dbe8f4] hover:text-[#6ea3ae]"
              href="/contact"
            >
              Contact
            </a>
          </li>
        </ul>

        <div className="hidden lg:flex fixed  top-[40%] ">
          <ul className>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-160px] hover:ml-[-58px] rounded-tr-lg duration-300 bg-[#6ea3ae]">
              <a
                className=" no-underline flex justify-between items-center w-full text-[#dbe8f4] "
                href="https://www.linkedin.com/in/willmashrick/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin <FaLinkedin size={30} />
              </a>
            </li>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-160px] hover:ml-[-58px]   duration-300 bg-[#6ea3ae]">
              <a
                className=" no-underline flex justify-between items-center w-full text-[#dbe8f4] hover:text-[#282828]"
                href="https://github.com/WillMashrick"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github <FaGithub size={30} />
              </a>
            </li>{" "}
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-160px] hover:ml-[-58px] rounded-br-lg duration-300 bg-[#6ea3ae]">
              <a
                className=" no-underline flex justify-between items-center w-full text-[#dbe8f4] hover:text-[#476967]"
                href={resume}
                download
              >
                Resume <BsFillPersonLinesFill size={30} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
