import React from "react";

import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Node from "../assets/node.png";
import GitHub from "../assets/github.png";
import Mongo from "../assets/mongo.png";
import ExpressImg from "../assets/express.png";

export default function Technology() {
  return (
    <div
      name="technology"
      className=" w-full h-full bg-[#282828] text-[#dbe8f4] font-[montserrat] "
    >
      {/* Container */}
      <div className=" max-w-[850px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className=" text-4xl text-[#6ea3ae] font-bold inline">Skills</p>
          <p className=" py-2">
            I have experience working with the following technologies.
          </p>
        </div>

        <div className=" w-full grid grid-cols-2  sm:grid-cols-4 gap-4 text-center py-8">
          <div className="  pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="  w-20 mx-auto" src={HTML} alt="HTML icon" />
            <p className=" my-4">HTML</p>
          </div>

          <div className=" pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="  w-20 mx-auto" src={CSS} alt="HTML icon" />
            <p className=" my-4">CSS</p>
          </div>
          <div className="  pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20 mx-auto" src={JavaScript} alt="HTML icon" />
            <p className=" my-4">JavaScript</p>
          </div>
          <div className=" pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20 mx-auto" src={GitHub} alt="HTML icon" />
            <p className=" my-4">GitHub</p>
          </div>
          <div className=" pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20 mx-auto" src={Mongo} alt="HTML icon" />
            <p className=" my-4">Mongo DB</p>
          </div>
          <div className=" pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20  mx-auto" src={ExpressImg} alt="HTML icon" />
            <p className=" my-4">Express JS</p>
          </div>
          <div className=" pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20 mx-auto" src={ReactImg} alt="HTML icon" />
            <p className=" my-4">React</p>
          </div>
          <div className="pt-4 rounded-lg shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className=" w-20 m-auto" src={Node} alt="HTML icon" />
            <p className=" my-4">Node JS</p>
          </div>
        </div>
      </div>
    </div>
  );
}
