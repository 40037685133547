import "./index.css";
import TopNav from "./components/TopNav.jsx";
import Home from "./components/Home.jsx";
import About from "./components/About";
import VisualArt from "./components/VisualArt";
import Music from "./components/Music";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <TopNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/visualart" element={<VisualArt />} />
        <Route path="/music" element={<Music />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
